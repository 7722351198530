<template>
  <div>
    <documents-table />
  </div>
</template>

<script>
import DocumentsTable from "@/components/DocumentsTable";
export default {
  name: "DiplomVerifications",
  components: { DocumentsTable },
};
</script>

<style scoped></style>
