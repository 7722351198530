<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab
          v-for="status in statuses"
          :key="status.name"
          @click="changeTab(status.value)"
          >{{ status.name }}</v-tab
        >
      </v-tabs>
      <v-row class="px-5 pt-4 pb-3" no-gutters>
        <v-col md="5">
          <v-text-field
            label="Поиск имя, email"
            :value="optionsTable.search"
            hide-details
            clearable
            @change="(value) => (optionsTable.search = value)"
            @click:clear="optionsTable.search = ''"
          >
          </v-text-field>
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-btn color="accent" elevation="0" width="40">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="optionsTable"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" scrollable max-width="500px">
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">Репетитор</span>
                <div class="text-h5 accent--text">
                  {{ itemCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12">
                    <v-simple-table>
                      <template #default>
                        <tbody>
                          <tr>
                            <td>ФИО:</td>
                            <td>
                              {{ editedItem.name }} {{ editedItem.lastname }}
                            </td>
                          </tr>
                          <tr>
                            <td>Email:</td>
                            <td>{{ editedItem.email }}</td>
                          </tr>
                          <tr>
                            <td>Учебное завдение:</td>
                            <td>{{ editedItem.institution }}</td>
                          </tr>
                          <tr v-if="editedItem.specs.length > 0">
                            <td>Специализация:</td>
                            <td>
                              <div
                                v-for="spec in editedItem.specs"
                                :key="spec.id"
                              >
                                {{ spec.subject.name.ru }} | {{ spec.name.ru }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex align-center">
                                Документы
                                <v-icon
                                  v-if="editedItem.comment"
                                  small
                                  color="warning"
                                  class="ml-1"
                                >
                                  mdi-alert-circle
                                </v-icon>
                              </div>
                            </td>
                            <td>
                              <b
                                v-html="
                                  editedItem.type === 'education'
                                    ? 'Диплом'
                                    : 'Сертификат'
                                "
                              ></b>
                              <b v-if="editedItem.education_degree"
                                >({{ editedItem.education_degree }})</b
                              >
                              <div
                                v-for="(f, i) in editedItem.files"
                                :key="f.id + 'f'"
                              >
                                <a :href="f.url" target="_blank"
                                  >{{ i + 1 }}. файл</a
                                >
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="d-inline-block">
                      <v-radio-group v-model="editedItem.accept">
                        <v-radio
                          label="Подтвердить документы"
                          color="accent"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="Не подтверждать документы"
                          color="accent"
                          :value="0"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                  <v-col
                    v-if="editedItem.accept === 0 || editedItem.comment"
                    cols="12"
                    class="py-0"
                  >
                    <v-textarea
                      v-model="editedItem.comment"
                      auto-grow
                      outlined
                      label="Комментарий*"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save" :disabled="disabledSend">
                  Отправить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.status="{ item }">
          <v-icon :color="status(item) === 'mdi-new-box' ? 'accent' : ''">
            {{ status(item) }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import api from "../../api";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "DocumentsTable",
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Статус",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Имя",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          align: "start",
        },
        {
          text: "Создано",
          value: "uploaded_at",
          align: "start",
        },
        {
          text: "Обновлено",
          value: "updated_at",
          align: "start",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      defaultItem: null,
      optionsTable: {
        statusActive: "all",
        search: "",
      },
      total: 0,
      loading: true,
      errors: {},
      statuses: [
        {
          value: "all",
          name: "Все",
        },
        {
          value: "new",
          name: "Новые",
        },
        {
          value: "verified",
          name: "Подтверждённые",
        },
        {
          value: "canceled",
          name: "Отклонённые",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      updateTime: "updateTime",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Новый предмет" : "Изменить";
    },
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].name : "";
    },
    disabledSend() {
      return (
        (!this.editedItem.accept && this.editedItem.accept !== 0) ||
        (this.editedItem.accept === 0 && !this.editedItem.comment)
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    optionsTable: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  created() {
    this.editedItem = { ...this.createItem() };
    this.defaultItem = { ...this.createItem() };
  },
  mounted() {
    setInterval(() => {
      this.getItems();
    }, this.updateTime);
  },
  methods: {
    ...mapActions({
      getDocsCount: "getDocsCount",
    }),
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      const loadItem = await this.getItem(item.id);
      this.editedItem = { ...loadItem };
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.errors = {};
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.updateItem();
        this.getItems();
      } else {
        await this.addItem();
        this.getItems();
      }
      this.getDocsCount();
    },
    async getItems() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage, statusActive, search } =
        this.optionsTable;
      try {
        const req = await api.documents.itemsList({
          sortBy: sortBy && sortBy.length > 0 ? sortBy[0] : null,
          sortDesc: sortDesc && sortDesc.length > 0 ? sortDesc[0] : null,
          page: page,
          itemsPerPage: itemsPerPage,
          status: statusActive,
          search: search,
        });
        if (req.data) {
          this.items = req.data.items;
          this.total = req.data.total;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async updateItem() {
      const data = {
        accept: this.editedItem.accept,
        comment: this.editedItem.comment,
      };
      try {
        await api.documents.updateItem(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Док-ты "${this.editedItem.name}" изменены`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },
    async getItem(id) {
      try {
        const req = await api.documents.item(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    createItem() {
      return {
        name: null,
        email: null,
        created_at: null,
        accept: null,
        comment: null,
      };
    },
    status(item) {
      let result = "mdi-new-box";
      if (item.verified_at) {
        result = "mdi-check-decagram";
      } else if (item.comment) {
        result = "mdi-cancel";
      }
      return result;
    },
    changeTab(status) {
      this.optionsTable.statusActive = status;
      this.optionsTable.search = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
